import React, { useState } from "react";
import useStyles from "../../ComplaintCategory/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { startApiCall } from "../../../../GlobalFunctions/GlobalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { AreaRoutes } from "../../../../api/Routes";
import { toast } from "react-toastify";
import ConfirmModal from "../../../../contextAPI/ConfirmModal";
import { navigateToComplaintSubSubArea } from "../../../../Navigation/Navigations";
import { useNavigate } from "react-router";
import { ArrowRightAlt, DeleteOutlined } from "@material-ui/icons";

const SubAreaTable = ({
  allSubArea,
  page,
  pageLimit,
  setEditSubarea,
  setisEditSubarea,
  editSubarea,
  handleGetAllSubArea,
  isReadWrite,
  puneplant
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [deleteSubArea, setDeleteSubArea] = useState(false);

  const handleReset = () => {
    setDeleteSubArea(false);
    setloader(false);
    seterrorMsg("");
  };

  const handleDeleteSubArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      isDeleted: true,
      subAreaId: editSubarea?._id,
    };
    const res = await ApiCall("post", AreaRoutes.editSubArea, data);
    if (res?.success) {
      toast.success("Subarea Deleted Successfully!");
      handleReset();
      handleGetAllSubArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Name
              </TableCell>
           
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Zone
              </TableCell>
              {isReadWrite && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  sx={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Action
                </TableCell>
              )}
               { puneplant && <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Detail Address
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {allSubArea?.map((subarea, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * pageLimit + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {subarea?.name}
                  </TableCell>
               
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {subarea?.zone}
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span
                        className="badge bg-primary"
                        onClick={() => {
                          setEditSubarea(subarea);
                          setisEditSubarea(true);
                        }}
                        style={{ width: "50px", padding: 8, cursor: "pointer" }}
                      >
                        <EditIcon style={{ fontSize: "12px" }} />
                      </span>
                      {/* <span
                      className="badge bg-danger ms-2"
                      onClick={() => {
                        setEditSubarea(subarea);
                        setDeleteSubArea(true);
                      }}
                      style={{
                        width: "50px",
                        padding: 8,
                        cursor: "pointer",
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: "12px" }} />
                    </span> */}
                    </TableCell>
                  )}
                 {  puneplant &&  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                 
                 Detail Address
                    <ArrowRightAlt
                      title="go to subsubarea page"
                      style={{ fontSize: 25, cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          navigateToComplaintSubSubArea({
                            subAreaId: subarea?._id,
                            subAreaName: subarea?.name,
                          })
                        )
                      }
                    />
                  </TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        title={`ARE YOU SURE YOU WANT TO DELETE ${editSubarea?.name} ?`}
        description="You can confirm by clicking below."
        loader={loader}
        isOpen={deleteSubArea}
        errorMsg={errorMsg}
        rejectConfirmation={handleReset}
        handleConfirmation={handleDeleteSubArea}
      />
    </>
  );
};

export default SubAreaTable;
