import React, { useState } from "react";
import useStyles from "../../ComplaintCategory/styles";
import Modal from "@mui/material/Modal";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close, ErrorOutline } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import ExploreIcon from "@mui/icons-material/Explore";
import EditIcon from "@mui/icons-material/Edit";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import ApiCall from "../../../../api/ApiCall";
import { startApiCall } from "../../../../GlobalFunctions/GlobalFunctions";
import { AreaRoutes } from "../../../../api/Routes";
import { toast } from "react-toastify";

const AddSubSubArea = ({ addSubSubArea, setAddSubSubArea,id,handleGetAllSubArea }) => {
  const classes = useStyles();
  const [subAreaName, setSubAreaName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const handleReset = () => {
    setAddSubSubArea(false);
    setloader(false);
    seterrorMsg("");
    setSubAreaName("");
  };

  const handleAddSubArea = async () => {
    startApiCall(seterrorMsg, setloader);
    const data = {
      name: subAreaName,
      societyId: fetchSocietyId(),
      subarea: id,
    };
    const res = await ApiCall("post", AreaRoutes.addSubsubArea, data);
    if (res?.success) {
      toast.success("Subarea2 Added Successfully!");
      handleReset();
      handleGetAllSubArea();
    } else {
      setloader(false);
      seterrorMsg(res.error);
    }
  };

  return (
    <Modal
      open={
        addSubSubArea
      }
    >
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          minWidth: "300px",
          maxWidth: "29vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Subarea2
          </Typography>
          <IconButton
            onClick={() => {
              handleReset();
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container item xs={3}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                textAlign: "center",
                marginTop: 12,
                marginLeft: 8,
              }}
            >
              Name :
            </Typography>
          </Grid>
          <Grid container item xs={9}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 4,
                outline: 0,
              }}
              placeholder="Enter Name"
                value={subAreaName}
                onChange={(event) => setSubAreaName(event.target.value)}
            />
          </Grid>
        </Grid>
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "150px",
                backgroundColor: loader ? "#7D858D" : "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
            disabled={loader}
            onClick={() => {
                handleAddSubArea();
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
      
                    {!loader ? "Add Subarea2" : `Adding...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AddSubSubArea;
