import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { KeyboardBackspaceRounded } from "@material-ui/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import useStyles from "../../ComplaintCategory/styles";
import { Grid, Typography } from "@material-ui/core";
import ExploreIcon from "@mui/icons-material/Explore";
import {  navigateToComplaintArea } from "../../../../Navigation/Navigations";

const SubSubAreaHeader = ({ isReadWrite, setAddSubSubArea,
  loader,handleGetAllSubArea,subAreaName,subAreaId
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Grid container style={{ justifyContent: "space-between" }}>
        <Grid item lg={6}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 18,
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
            }}
          >
            Complaint&nbsp;
            <span
              style={{ cursor: "pointer" }}
            >{` > Area `}</span>
            &nbsp;
            <span style={{ color: "#000" }}>{` > Location `}</span>&nbsp;
            <span style={{ color: "#000" }}>{` > Detail Address `}</span>
            {!loader ? (
            <RefreshIcon
              title="refresh Subarea Page"
              onClick={handleGetAllSubArea}
              style={{
                marginLeft: 5,
                cursor: "pointer",
                color: "#000",
              }}
            />
           ) : (
              <LoadingOutlined
                title="laoding..."
                style={{ marginLeft: 5, color: "#000" }}
              />
            )}
          </Typography>
        </Grid>
        <Grid container item lg={6} style={{ justifyContent: "flex-end" }}>
          {isReadWrite && (
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "190px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginRight: 15,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setAddSubSubArea(true);
                  }}
                >
                  <ExploreIcon
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                  Add Detail Address
                </p>
              </div>
            </div>
          )}
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "90px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(navigateToComplaintArea(
            
              ));
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                <KeyboardBackspaceRounded
                  style={{
                    marginRight: 10,
                    fontSize: "15px",
                  }}
                />
                Back
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 30, justifyContent: "space-between" }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 15,
            fontFamily: "Montserrat, sans-serif",
            color: "#6c757d",
            marginLeft: 12,
          }}
        >
          Location Name :&nbsp;
          <span
            style={{
              color: "#000",
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {subAreaName}
          </span>
        </Typography>
      </Grid>
    </>
  );
};

export default SubSubAreaHeader;
