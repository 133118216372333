import React, { useContext, useEffect, useState } from "react";
import SubSubAreaTable from "./SubSubAreaTable";
import SubSubAreaHeader from "./SubSubAreaHeader";
import { AuthContext } from "../../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import useStyles from "../../ComplaintCategory/styles";
import { Grid, Typography } from "@material-ui/core";
import AddSubSubArea from "./AddSubSubArea";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import ApiCall from "../../../../api/ApiCall";
import { useParams } from "react-router";
import { startApiCall } from "../../../../GlobalFunctions/GlobalFunctions";
import { AreaRoutes } from "../../../../api/Routes";
import { Pagination } from "@mui/material";

const SubSubArea = () => {
  const classes = useStyles();
  const { id, subareaName} = useParams();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [addSubSubArea, setAddSubSubArea] = useState(false);

  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [allSubSubArea, setAllSubSubArea] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [pageLimit] = useState(30);
  
  console.log("useParams()",useParams())
  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Area")
      );
    }
  }, [usermoduleswithpermissions]);

  const handleGetAllSubArea = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        subarea: id,
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", AreaRoutes.getsubsubarea, data);
    if (res?.success) {
      setAllSubSubArea(res?.area?.data);
      setCount(res?.area?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllSubArea();
  }, [page]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };
  return (
    <div
      className={classes.root}
        style={{
          filter: loader ? "blur(1px)" : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <SubSubAreaHeader
                isReadWrite={isReadWrite}
                setAddSubSubArea={setAddSubSubArea}
                loader={loader}
                handleGetAllSubArea={handleGetAllSubArea}
                subAreaName={subareaName}
                subAreaId={id}
              />
              {!loader && allSubSubArea?.length > 0 && (
              <Grid
                style={{ marginTop: 16, marginBottom: 10, marginRight: 10 }}
              >
                <SubSubAreaTable
                    allSubSubArea={allSubSubArea}
                    page={page}
                    pageLimit={pageLimit}
                    handleGetAllSubArea={handleGetAllSubArea}
                isReadWrite={isReadWrite} />
              </Grid>
              )} 
         {allSubSubArea?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Subarea2
                  </Typography>
                </Grid>
              )} 
              {allSubSubArea?.length > 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )} 
            </div>
          </div>
        </Grid>
        <AddSubSubArea
          addSubSubArea={addSubSubArea}
          setAddSubSubArea={setAddSubSubArea}
            id={id}
          //   editSubarea={editSubarea}
          //   isEditSubarea={isEditSubarea}
          //   setisEditSubarea={setisEditSubarea}
            handleGetAllSubArea={handleGetAllSubArea}
        />
      </main>
      {/* {loader && <BanterLoader />} */}
    </div>
  );
};

export default SubSubArea;
