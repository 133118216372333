import React, { useReducer, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "./contextAPI/context";
import { authReducer } from "./contextAPI/reducer";
import * as api from "./api";
import {
  GETUSER,
  PERMISSIONMODULES,
  SOCIETIES,
  USERMODULESWITHPERSMISSIONS,
  MEMBERTYPE,
  NAMINGCONVENTION,
  SOCIETYCONFIGURATION,
  PLANT,
  PUNEPLANT,
} from "./contextAPI/actions/auth.actions";
import { ToastContainer } from "react-toastify";
import AuthRoutes from "./MainRoutes/Authentication/AuthRoutes";
import MainRoutes from "./MainRoutes";
import {
  getAllPermissionModules,
  getSocietyConfiguration,
} from "./GlobalFunctions/GlobalFunctions";
import { fetchSocietyDetails, fetchSocietyId } from "./contextAPI/fetchSociety";
import SignalLoader from "./GlobalComponents/Loaders/SignalLoader";

const App = () => {
  const [authState, authDispatch] = useReducer(authReducer, {});
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const verifyUser = async () => {
    try {
      const res = await api.getUser({ params: { token: refreshToken } });
      if (res?.data.user) {
        const allPermissionModules = await getAllPermissionModules({
          societyId: fetchSocietyId(),
          userId: res?.data.user?._id,
        });
        const societyConfiguration = await getSocietyConfiguration({
          societyId: fetchSocietyId(),
          adminId: res?.data.user?._id,
        });
        const isPlant = await fetchSocietyDetails("isPlant");
        const isPunePlant = await fetchSocietyDetails("isPunePlant");
        authDispatch({
          type: NAMINGCONVENTION,
          payload: societyConfiguration?.propertyNaming
            ? societyConfiguration?.propertyNaming
            : [],
        });
        authDispatch({
          type: SOCIETYCONFIGURATION,
          payload: societyConfiguration ? societyConfiguration : [],
        });
        authDispatch({
          type: PLANT,
          payload: isPlant,
        });
        authDispatch({
          type: PUNEPLANT,
          payload: isPunePlant,
        });
        authDispatch({
          type: GETUSER,
          payload: res.data,
        });
        authDispatch({
          type: USERMODULESWITHPERSMISSIONS,
          payload: allPermissionModules?.permissions,
        });
        let modulesNames = allPermissionModules?.permissions?.map((per) => {
          return per.moduleName;
        });
        let societies = res.data.user.societies.map((soc) => {
          return soc;
        });
        authDispatch({
          type: MEMBERTYPE,
          payload: allPermissionModules?.role,
        });
        authDispatch({
          type: PERMISSIONMODULES,
          payload: modulesNames ? modulesNames : [],
        });
        authDispatch({
          type: SOCIETIES,
          payload: societies,
        });
        setisLoggedIn(true);
        setIsLoaded(true);
      }
    } catch (error) {
      setisLoggedIn(false);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  return isLoaded ? (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {!isLoggedIn ? (
        <Routes>
          <Route path="/*" element={<AuthRoutes />} />
        </Routes>
      ) : (
        <MainRoutes />
      )}
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </AuthContext.Provider>
  ) : (
    <SignalLoader />
  );
};

export default App;
