import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import ApiCall from "../../api/ApiCall";
import { AreaRoutes } from "../../api/Routes";
import { startApiCall } from "../../GlobalFunctions/GlobalFunctions";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseSubArea2DropDown({
  selectedSubArea2,
  setselectedSubArea2,
  selectedSubArea,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allSubArea, setallSubArea] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [page, setpage] = useState(1);
  const [searchKey, setsearchKey] = useState("");

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== allSubArea?.length
    ) {
      setpage((prev) => prev + 1);
    }
  };

  const handleGetAllSubArea = async () => {
    startApiCall(null, setLoading);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        subarea: selectedSubArea?._id,
        isDeleted: false,
        searchKey,
      },
      page: page,
      limit: 10,
    };
    const res = await ApiCall("post", AreaRoutes.getsubsubarea, data);
    if (res?.success) {
      if (page === 1) {
        setallSubArea(res?.area?.data);
        settotalCount(res?.area?.totalCounts);
      } else {
        setallSubArea((prev) => [...prev, ...res?.area?.data]);
        settotalCount(res?.area?.totalCounts);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSubArea?._id) {
      handleGetAllSubArea();
    }
  }, [selectedSubArea, searchKey, page]);

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={false}
        onChange={(e, value) => {
          setselectedSubArea2(value);
        }}
        id="sub-search"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={selectedSubArea2}
        disabled={!selectedSubArea}
        classes={classes}
        options={allSubArea}
        autoHighlight
        getOptionLabel={(option) => `${option?._id ? option.name : ""}`}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option._id}>
                {selectedSubArea2?.length > 0 &&
                  selectedSubArea2.includes(option) && (
                    <CheckCircle
                      style={{
                        color: "rgb(36 202 38)",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                {option?.name}
              </li>
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required
              {...params}
              onChange={(e) => {
                setpage(1);
                setsearchKey(e.target.value);
              }}
              onBlur={() => {
                setsearchKey("");
              }}
              label={loading ? "Loading..." : `Select Detail Address (${totalCount})`}
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseSubArea2DropDown;
